import ApiService from "@/core/services/ApiService";
import { WebUser } from "@/core/services/responsesModel/webUser"

// 查詢網頁票券的使用者
export function getWebUser(tokenGuid): Promise<WebUser> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Tokens/${tokenGuid}/WebUser`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}