import ApiService from "@/core/services/ApiService";
import { IApiResponseInvoices } from "../responsesModel/invoice";



// 取得帳單的發票
export function getInvoices(request): Promise<IApiResponseInvoices> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Invoices/QueryByPayments`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

