<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <div class="col-lg col-12 d-flex align-items-center overflow-auto">
          <el-date-picker
            class="ms-xxl-5 ms-xl-5 ms-0"
            v-model="value"
            type="datetimerange"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            @change="updateTime"
          >
          </el-date-picker>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button class="btn btn-success" @click="exportData()">匯出</button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-tableRunningNumber="{ row: tableData }">
          {{ tableData.tableRunningNumber }}
        </template>
        <template v-slot:cell-tableUser="{ row: tableData }">
          {{ tableData.tableUser }}
        </template>
        <template v-slot:cell-tableMode="{ row: tableData }">
          {{ tableData.tableMode }}
        </template>
        <template v-slot:cell-tableStatus="{ row: tableData }">
          {{ tableData.tableStatus }}
        </template>
        <template v-slot:cell-remark="{ row: tableData }">
          {{ tableData.remark }}
        </template>
        <template v-slot:cell-tableCreateTime="{ row: tableData }">
          {{ tableData.tableCreateTime }}
        </template>
        <template v-slot:cell-thirdPartSource="{ row: tableData }">
          {{ tableData.thirdPartSource }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentItem(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toBillListPage()"
                  >查看帳單</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, toRefs, ref } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";
import {
  getOrderBySpace,
  orderModeDictionary,
  orderStatusDictionary,
} from "@/core/services/api/order";
import { getMembersBySpace } from "@/core/services/api/members";
import { getWebUser } from "@/core/services/api/webUser";
import { ItemType } from "@/core/services/responsesModel/orderExtend";
import { Order } from "@/core/services/responsesModel/orderExtend";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getTokens } from "@/core/services/api/tokens";
import { getCoupons } from "@/core/services/api/coupons";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import _ from "lodash";
import { getInvoices } from "@/core/services/api/invoice";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    let itemTypeMap = {
      Token: "票券",
      Coupon: "折價券",
    };
    let paymentStatusMap = {
      Unpaid: "尚未付款",
      Captured: "已請款",
      Refunded: "已退款",
    };
    let paymentMethodMap = {
      FreeByDiscount: "折價到免費",
      TappayDirectPay: "使用tappay付款",
      SpacesPoint: "使用點數付款",
      Manual: "業主開立稍後請款",
    };
    let facilitiesDictionary = new Map();
    let ordersData;
    const router = useRouter();
    const route = useRoute();
    let currentItem: any = {};
    const isLoadind = ref(false);
    const search: any = ref<string>("");
    let partnerMap = {
      None: "無",
      Metro: "台北捷運",
    };
    // table資料
    let tableData: any = reactive([]);
    let initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "訂單編號",
        key: "tableRunningNumber",
        sortable: true,
      },
      {
        name: "使用者",
        key: "tableUser",
        sortable: true,
      },
      {
        name: "模式",
        key: "tableMode",
        sortable: true,
      },
      {
        name: "狀態",
        key: "tableStatus",
        sortable: true,
      },
      {
        name: "服務記事",
        key: "remark",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "tableCreateTime",
        sortable: true,
      },
      {
        name: "第三方來源",
        key: "thirdPartSource",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    let spaceMembers;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const timePickerData: any = reactive({
      value: [
        moment()
          .subtract(2, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });
    // 時間過濾
    let timeFilter = {
      beginTime: timePickerData.value[0],
      endTime: timePickerData.value[1],
    };

    // ------方法------

    const getFacility = (guid) => {
      if (facilitiesDictionary.has(guid)) {
        return facilitiesDictionary.get(guid);
      } else {
        return guid;
      }
    };

    // 設置設施字典
    const setFacilityDictionary = async () => {
      let facilityArray = (await getFacilityBySpace(currentSpaceGuid!)).map(
        (o) => o.facility
      );

      facilitiesDictionary = new Map();
      facilityArray.forEach((o) => {
        facilitiesDictionary.set(o.guid, o);
      });
    };

    // 根據優惠券陣列取得優惠券資料
    const getCouponsDataByCouponsGuid = async (couponsGuid) => {
      let request = {
        codesGuid: couponsGuid,
      };
      let coupons = await getCoupons(request);
      return coupons;
    };

    // 根據票券陣列取得票券資料
    const getTokensDataByTokensGuid = async (tokensGuid) => {
      let request = {
        tokens: tokensGuid,
      };
      let tokens = await getTokens(request);
      return tokens;
    };


  

    // 取出訂單票券
    const getOrdersTokens = (orders) => {
      let tokens: any = [];
      orders.forEach((o) => {
        o.lineItems.forEach((i) => {
          if (i.type === ItemType.Token) {
            tokens.push(i.item);
          }
        });
      });
      return Array.from(new Set(tokens));
    };

    // 取出優惠券
    const getOrdersCoupons = (orders) => {
      let coupons: any = [];
      orders.forEach((o) => {
        o.lineItems.forEach((i) => {
          if (i.type === ItemType.Coupon && i.item !== "") {
            coupons.push(i.item);
          }
        });
      });
      return Array.from(new Set(coupons));
    };

    // 取得帳單資料
    const getPaymentData = (payments, paymentGuid) => {
      return payments.filter((o) => o.guid == paymentGuid)[0];
    };

    // 根據票券guid取出票券資料
    const filterTokensDataByGuid = (tokensData, guid) => {
      return tokensData.filter((o) => o.guid === guid)[0];
    };

    // 根據優惠券guid取出優惠券資料
    const filterCouponsDataByGuid = (couponsData, guid) => {
      return couponsData.filter((o) => o.codeGuid === guid)[0];
    };

    // 匯出資料
    const exportData = () => {
      Swal.fire({
        title: "確定要匯出資料嗎?",
        text: "資料將僅根據您過濾的時間匯出",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("ordersData", ordersData);
          let tokens = getOrdersTokens(ordersData);
          let coupons = getOrdersCoupons(ordersData);
          console.log("coupons", coupons);
          let tokensData = await getTokensDataByTokensGuid(tokens);
          let couponsData: any = [];
          if (coupons.length !== 0) {
            couponsData = await getCouponsDataByCouponsGuid(coupons);
          }
          const paymentsGuid = _.uniq(ordersData.flatMap(o=>o.payments).map(o=>o.guid))
          const apiResponseInvoiceData = await getInvoices({payments:paymentsGuid});
          console.log("apiResponseInvoiceData",apiResponseInvoiceData);
          const invoicesData =apiResponseInvoiceData.invoices;
          console.log("invoicesData",invoicesData);

          await setFacilityDictionary();
          //要匯出的json資料
          var jsonData: any = [];
          tableData.forEach((o) => {
            o.lineItems.forEach((i) => {
              console.log("i", i);
              let paymentData = getPaymentData(o.payments, i.payment);
              let token = filterTokensDataByGuid(tokensData, i.item);
              let coupon = filterCouponsDataByGuid(couponsData, i.item);
              console.log("paymentData", paymentData);
              const inoviceData =paymentData?  invoicesData.find(o=>o.payment==paymentData.guid):undefined
            
              jsonData.push({
                //訂單編號
                orderRunningNumber: o.tableRunningNumber,
                //訂單模式
                orderMode: o.tableMode,
                //訂單狀態
                orderStatus: o.tableStatus,
                //訂單總額
                orderTotal: o.orderTotal,
                //帳單編號
                paymentRunningNumber: paymentData
                  ? paymentData.runningNumber
                  : "",
                //購買人
                buyer: paymentData ? paymentData.contact.name : "",
                //購買人Email
                buyerEmail: paymentData ? paymentData.contact.email : "",
                //購買人電話
                buyerPhone: paymentData ? paymentData.contact.phone : "",
                // 第三方來源
                thirdPartSource: o.thirdPartSource,
                // 第三方使用者識別
                thirdPartUserId: o.thirdPartUserId,
                //購買時間
                purchaseTime: getLocalTimeString(o.order.createTime),
                //帳單類型
                paymentMethod: paymentData
                  ? paymentMethodMap[paymentData.method]
                  : "",
                //帳單狀態
                paymentStatus: paymentData
                  ? paymentStatusMap[paymentData.serverStatus]
                  : "",
                //帳單總額
                paymentAmount: paymentData ? paymentData.amount : "",
                //品項類型
                itemType: itemTypeMap[i.type],
                //票券編號
                tokenRunningNumber: token ? token.runningNumber : "",
                //品項名稱
                itemName:
                  i.type == "Token"
                    ? getFacility(token.facility).name
                    : coupon
                    ? coupon.title
                    : "",
                //起始時間
                beginTime:
                  i.type == "Token"
                    ? getLocalTimeString(token.beginTime)
                    : coupon
                    ? getLocalTimeString(coupon.beginTime)
                    : "",
                //結束時間
                endTime:
                  i.type == "Token"
                    ? getLocalTimeString(token.endTime)
                    : coupon
                    ? getLocalTimeString(coupon.endTime)
                    : "",
                //單位時間價格
                timeunitPrice:
                  i.type == "Token"
                    ? i.chargeExtend
                      ? i.chargeExtend.amount
                      : ""
                    : "",
                //單位時間
                timeunit:
                  i.type == "Token"
                    ? i.chargeExtend
                      ? i.chargeExtend.rule.timeSpan + "分鐘"
                      : ""
                    : "",
                //數量
                quantity: i.type == "Token" ? i.quantity : "",
                //小計
                countAmount: i.total,
                // 服務記事
                remark: o.remark,
                // 發票稅月
                inoviceTaxMonth: inoviceData?.taxMonth??"",
                // 發票
                inovice: inoviceData?.number??"",
                // 發票隨機碼
                inoviceRandom: inoviceData?.randomNumber??""
              });
            });
          });
          //列標題，逗號隔開，每一個逗號就是隔開一個單元格
          let str = `訂單編號,訂單模式,訂單狀態,訂單總額,帳單編號,購買人,購買人Email,購買人電話,第三方來源,第三方使用者識別,購買時間,帳單類型,帳單狀態,帳單總額,品項類型,票券編號,品項名稱,起始時間,結束時間,單位時間價格,單位時間,數量,小計,服務記事,發票稅月,發票號碼,發票隨機碼\n`;
          //增加\t為了不讓表格顯示科學計數法或者其他格式
          for (let i = 0; i < jsonData.length; i++) {
            for (let item in jsonData[i]) {
              str += `${jsonData[i][item]},`;
            }
            str += "\n";
          }
          //encodeURIComponent解決中文亂碼
          let uri =
            "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
          //通過建立a標籤實現
          var link = document.createElement("a");
          link.href = uri;
          //對下載的檔案命名
          link.download = `訂單報表(${getLocalTimeString(
            timeFilter.beginTime
          )}~${getLocalTimeString(timeFilter.endTime)}).csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    };

    const toBillListPage = () => {
      router.push({
        name: "billList",
        params: { orderRunningNumber: currentItem.order.runningNumber },
      });
    };

    const setCurrentItem = (item) => {
      currentItem = item;
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const updateTime = (time) => {
      timeFilter.beginTime = getUtcTimeString(time[0]);
      timeFilter.endTime = getUtcTimeString(time[1]);
      setTableData();
    };

    const handleGetOrderBySpace = async () => {
      let ordersRequestBody: any = {
        space: localstorage.getCurrentSpaces()?.space.guid,
        beginTime: timeFilter.beginTime,
        endTime: timeFilter.endTime,
      };
      return await getOrderBySpace(ordersRequestBody);
    };

    const getUserName = async (order) => {
      let orderUser = order.order.user;
      const result = spaceMembers.filter((o) => o.guid === orderUser);
      if (result.length > 0) {
        const name = result[0].name;
        if (name === "") return result[0].email;
        return name;
      } else {
        if (orderUser === "defaultWebUser") {
          let tokenGuid = order.lineItems.filter(
            (o) => o.type === ItemType.Token
          )[0].item;
          let webUserData = await getWebUser(tokenGuid);
          return webUserData.name;
        }
        return orderUser;
      }
    };

    const getOrderTotal = (order) => {
      let payments = order.payments;
      let orderTotal = 0;
      payments.forEach((o) => {
        orderTotal = orderTotal + o.amount;
      });
      return orderTotal;
    };

    const setTableData = async () => {
      let orders = await handleGetOrderBySpace();
      let ordersCopy = JSON.parse(JSON.stringify(orders));
      spaceMembers = await getMembersBySpace(currentSpaceGuid!);
      for (const order of ordersCopy) {
        order.tableRunningNumber = order.order.runningNumber;
        order.tableUser = await getUserName(order);
        order.tableMode = orderModeDictionary[order.order.mode];
        order.tableStatus = orderStatusDictionary[order.order.status];
        order.tableCreateTime = getLocalTimeString(order.order.createTime);
        order.thirdPartSource = partnerMap[order.order.partner];
        order.thirdPartUserId = order.order.partnerUserId;
        order.orderTotal = getOrderTotal(order);
        order.remark = order.order.remark;
      }
      ordersData = orders;
      tableData.splice(0, tableData.length, ...ordersCopy);
      initTableData.splice(0, initTableData.length, ...ordersCopy);
    };

    // 設置初始搜尋
    const setInitSearch = () => {
      console.log("route.params.orderNumber", route.params.orderNumber);
      if (!route.params.orderNumber != true) {
        search.value = route.params.orderNumber;
        console.log("search.value", search.value);
      } else {
        search.value = "";
        console.log("search.value =''", search.value);
      }
    };

    const init = async () => {
      isLoadind.value = true;
      await setTableData();
      isLoadind.value = false;
      setInitSearch();
      tableSearch();
    };
    init();

    return {
      ...toRefs(timePickerData),
      updateTime,
      search,
      tableSearch,
      tableHeader,
      tableData,
      isLoadind,
      currentItem,
      setCurrentItem,
      toBillListPage,
      exportData,
    };
  },
});
</script>

<style>
</style>